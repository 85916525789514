.clr-title {
  padding: 30px;
  background-color: $accent2;
  color: $white;
  font-family: $font;
  font-weight: 700;
  margin: 0;
  border: 15px solid $accent1;
  // border-radius: 10px 10px 0 0;
}

p {
  font-family: $font;
  font-weight: 400;
  color: $text;
}

h4 {
  padding: 0;
  color: $text;
  border: none;
  background-color: transparent;
  margin: 3.5rem 0 2rem;
}

.text-after-form {

  h4,
  p {
    margin: 0;
    padding: 0;
  }
}

.grey-btn {
  border: none;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  height: 90px;
  background-color: rgba($grey, 0.25);
  // border-radius: 10px;
  font-family: $font;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: $text;
  margin-top: 1.8rem;

  .icon {
    margin-left: 0.5rem;
    transition: all 0.3s ease-out;
  }

  &:hover {
    .icon {
      transform: translate(5px, 0);
    }
  }
}

.personal-advice-from {
  &__inner {
    padding: 15px;
    // border: 3px solid $green;
    // border-radius: 0 0 10px 10px;
    background-color: $accent1;
    border-top: none;
    position: relative;

    .input-element input,
    .date-element .bg-acc1,
    .select-element .select-area .select-item,
    .select-element .select-area .select-item.checked {
      border: 3px solid $white;
    }
  }

  .teaser.wide {
    border: none;
    display: flex;

    img {
      display: block;
      max-width: 100%;
      min-width: 300px;
      height: auto;
      opacity: 1;
    }

    p {
      padding-left: 30px;
    }
  }

  input,
  label,
  button,
  .date-input {
    display: block;
    width: 100%;
    padding: 30px;
    margin: 30px 0;
    font-family: $font;
    font-weight: 400;
    color: $text;
    outline: none;
    border: 3px solid $light-grey;

    // border-radius: 10px;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $text;
      opacity: 1;
      font-family: $font;
      font-weight: 400;
      font-style: italic;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $text;
      opacity: 1;
      font-family: $font;
      font-weight: 400;
      font-style: italic;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $text;
      opacity: 1;
      font-family: $font;
      font-weight: 400;
      font-style: italic;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: $text;
      opacity: 1;
      font-family: $font;
      font-weight: 400;
      font-style: italic;
    }
  }

  #date {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  label {
    position: relative;
    font-family: $font;
    font-weight: 400;
    font-style: italic;

    &.selected {
      font-family: $font;
      font-weight: 400;
    }
  }

  button {
    border-color: $red;
    background-color: $red;
    color: $white;
    text-transform: uppercase;
    font-family: $font;
    font-weight: 700;

    .icon {
      transition: all 0.3s ease-out;
      transform: translate(0, 2px);
    }

    &:hover {
      .icon {
        transform: translate(5px, 2px);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .personal-advice-from {
    h5 {
      padding: 15px;
    }

    &__inner {
      padding: 15px;
    }

    .teaser {
      flex-flow: row wrap;

      p {
        padding: 5px;
      }
    }

    h4,
    p {
      margin: 15px 0;
    }

    p {
      padding: 5px;
    }

    input,
    button,
    &>>>.date-input {
      padding: 15px;
    }

    button {
      margin: 15px 0;
    }
  }

  .text-after-form {
    margin-bottom: 30px;
  }

  .grey-btn {
    height: 60px;
  }
}

h3,
p {
  color: $text;
  margin: 0;
  padding: 0;
}

h3 {
  font-family: $font;
  font-weight: 700;
}

p {
  font-family: $font;
  font-weight: 400;

  &+p {
    margin-top: 3rem;
  }

  a {
    color: $accent2;
    text-decoration: underline;
    font-family: $font;
    font-weight: 700;
    transition: all 0.3s ease-out;

    &:hover {
      color: lighten($accent2, 10%);
    }
  }
}

.teaser.blue {
  border: none;
  display: flex;
  // border-radius: 10px;
  overflow: hidden;

  // min-height: 150px;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    max-width: 150px;
    padding: 15px;
    background-color: $grey;

    img {
      display: block;
      max-width: 60%;
      height: auto;
      opacity: 1;

      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
    }
  }

  .text {
    background-color: $accent2;
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
  }

  p {
    font-family: $font;
    font-weight: 700;
    color: $white;
    font-size: 1.25rem;
    line-height: 1.3;
  }

  a {
    color: $accent2;
    text-decoration: underline;
    font-family: $font;
    font-weight: 700;
  }
}
