.search-panel {
  font-family: $font;
  font-weight: 400;
  color: $text;

  .plz {
    font-family: $font;
    font-weight: 700;
    color: $accent2;
  }

  .filter-val {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $accent1;
    // border-radius: 10px;
    position: relative;
    background-color: $accent1;
    color: $accent2;
    padding: 0 10px;
    font-family: $font;
    font-weight: 700;
    cursor: pointer;
    margin: 0 0.5rem;

    .icon {
      margin-left: 5px;
      transform-origin: 50% 50%;
      transition: all 0.3s ease-out;
    }

    ul {
      overflow: hidden;
      display: block;
      position: absolute;
      z-index: 10;
      top: 100%;
      left: 0;
      min-width: 100%;
      width: auto;
      height: 0;
      opacity: 0;
      background-color: $white;
      border: 1px solid $accent1;
      // border-radius: 10px;
      margin: 0;
      padding: 0 10px;
      list-style: none;
      transition: all 0.3s ease-out;

      a {
        text-decoration: none;
        color: $accent2;
      }
    }

    &--show-items {
      .icon {
        transform: rotate(180deg);
      }

      ul {
        height: auto;
        opacity: 1;
        top: 110%;
      }
    }
  }

  p+p {
    margin-top: 0.5rem;
  }

  .btn-filter-collapse {
    font-family: $font;
    font-weight: 700;
    color: $accent2;
    transition: all 0.3s ease-out;

    // border-radius: 10px;
    .icon {
      transition: all 0.3s ease-out;
    }

    &.open .icon {
      transform: rotate(180deg);
    }
  }

  .filter-panel {
    background-color: rgba($accent1, 0.5);
    padding: 0.25rem;
    padding-bottom: 0;
    // border-radius: 10px;

    .btn-filter {
      font-family: $font;
      font-weight: 400;
      // border-radius: 10px;
      margin-bottom: 0.25rem;
      border-radius: 0;
      color: $accent2;
      background-color: $white;
      transition: all 0.3s ease-out;

      &.active {
        font-family: $font;
        font-weight: 700;
        background-color: $accent2;
        color: $white;
      }
    }
  }
}

.clinics-list {
  max-height: 75vh;
  overflow-y: scroll;

  p+p {
    margin-top: 0.5rem;
  }

  .clinic-teaser {
    display: block;
    position: relative;
    padding: 1.5rem 0;
    border-top: 1px solid $accent1;
    border-bottom: 1px solid $accent1;
    text-decoration: none;

    &+.clinic-teaser {
      border-top: none;
    }

    small {
      font-family: $font;
      font-weight: 400;
      color: $text;
    }

    &__img {
      width: 250px;
      height: auto;
    }

    &__info {
      h4 {
        font-family: $font;
        font-weight: 700;
        margin: 0;
        padding: 0;
        color: $accent2;
      }

      address {
        font-family: $font;
        font-weight: 400;
        margin: 0;
        padding: 0;
        color: $text;
      }
    }

    &__status {
      font-family: $font;
      font-weight: 400;
      text-align: right;
      padding-left: 0.5rem;
      color: $text;

      span {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: $light-grey;
        margin: 0 0 0 0.25rem;

        &:first-child {
          &.active {
            background-color: $green;
          }
        }

        &:nth-child(2) {
          &.active {
            background-color: $yellow;
          }
        }

        &:nth-child(3) {
          &.active {
            background-color: $red;
          }
        }
      }
    }

    .mini-cat {
      line-height: 1;
      font-family: $font;
      font-weight: 400;
      font-size: 0.9rem;
      // border-radius: 7px;
      border: 1px solid $accent1;
      background-color: $accent1;
      color: $accent2;
      margin-right: 0.5rem;
      margin-top: 0.25rem;
      padding: 3px 5px;
    }
  }

  @media screen and (max-width: 992px) {
    max-height: 50vh;
    width: 85%;
    margin: 0 auto;

    .clinic-teaser {
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 15px 0;

      &__img {
        width: 100%;
        max-width: 320px;
        margin-bottom: 15px;
      }

      &__status span {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }
}
