.select-element {
  margin: $marginSpace 0;

  .icon-checked {
    top: 50%;
    // left: 45px;
    left: 30px;
    transform: translate(-50%, -50%);
  }

  .select-area {
    position: relative;
    width: 100%;
    margin: 10px 0 $marginSpace;
    // border: 2px solid transparent;
    border-bottom: none;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    box-sizing: border-box;
    transition: width 0.3s ease-out;

    &.open {
      // width: 130%;
      border-color: $border;

      .options {
        height: auto;
        opacity: 1;
      }

      .selected-item {
        border-color: transparent;
      }
    }

    .select-item {
      cursor: pointer;
      display: flex;
      // padding: 30px;
      padding: 20px 10px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      font-family: $font;
      font-weight: 400;
      font-style: italic;
      color: $accent2;
      border: 3px solid $accent1;
      background-color: $accent1;
      // border-radius: 10px;
      transition: width 0.3s ease-out;

      &:hover {
        border: 3px solid $accent2;
      }

      &.checked {
        background-color: $accent2;
        color: $white;
        border-color: $accent2;
        // border-radius: 10px;

        .label {
          font-family: $font;
          font-weight: 700;
          // padding: 0px 0px 0px 60px;
          padding: 0px 0px 0px 50px;
          transition: all 0.3s ease-out;
        }

        .icon-checked {
          background-color: $white;
          color: $accent2;
          opacity: 1;
          z-index: 1;
        }
      }
    }

    .options {
      background-color: #fff;
      border: 1px solid $accent1;
      // border-bottom-left-radius: 10px;
      // border-bottom-right-radius: 10px;
      height: 0;
      max-height: 50vh;
      position: absolute;
      top: 100%;
      left: -1px;
      z-index: 10;
      width: calc(100% + 2px);
      border-top: none;
      opacity: 0;
      overflow: hidden;
      overflow-y: auto;
      transition: opacity 0.3s ease-out;

      .option {
        position: relative;
        border-top: 1px solid $accent1;

        .label {
          color: $accent2;
          cursor: pointer;
          display: block;
          margin: 0;
          // padding: 30px 30px 30px 90px;
          padding: 20px 10px 20px 60px;
          position: relative;
          font-family: $font;
          font-weight: 700;
          border: none;
          user-select: none;
          border-radius: 0;
          background-color: $white;
          transition: all 0.3s ease-out;

          &:before {
            content: "";
            position: absolute;
            display: block;
            // width: 30px;
            width: 25px;
            // height: 30px;
            height: 25px;
            top: 50%;
            // left: 45px;
            left: 30px;
            border: 3px solid $white;
            background-color: $accent1;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &.checked {
          .label {
            background-color: $accent2;
            color: $white;
            border-color: $accent2;
            // border-radius: 10px;
          }

          .icon-checked {
            background-color: $white;
            color: $accent2;
            opacity: 1;
            z-index: 1;
          }
        }

        &.disabled {
          .label {
            color: $light-grey;
            cursor: not-allowed;

            &:before {
              background-color: $light-grey;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .selected-area {
      margin: 10px 0 30px;
      height: 60px;

      &.open {
        width: 100%;
      }
    }

    .option {
      label {
        padding: 20px 10px 20px 60px;
      }
    }
  }
}
