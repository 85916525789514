.radio-element {
  scroll-margin-top: 5rem;
  margin-bottom: $marginSpace;
  position: relative;

  input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  label {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .in-element {
    color: $accent2;
    cursor: pointer;
    display: flex;
    margin-top: $marginSpace;
    // padding: 30px 30px 30px 90px;
    padding: 20px 10px 20px 60px;

    position: relative;
    font-family: $font;
    font-weight: 700;
    border: 3px solid $accent1;
    // border-radius: 10px;
    background-color: $accent1;
    transition: all 0.3s ease-out;
    flex: 1;
    align-items: center;

    &:hover {
      border: 3px solid $accent2;
    }

    .icon-checked {
      top: 50%;
      // left: 45px;
      left: 30px;
      transform: translate(-50%, -50%);
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      // width: 30px;
      width: 25px;
      // height: 30px;
      height: 25px;
      top: 50%;
      // left: 45px;
      left: 30px;

      border: 3px solid $white;
      background-color: $white;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    &.checked {
      background-color: $accent2;
      color: $white;
      border-color: $accent2;

      .icon-checked {
        background-color: $white;
        color: $accent2;

        opacity: 1;
      }
    }
  }
}
