.checkbox-element {
  margin: $marginSpace 0;

  .check-cols {
    max-width: 20%;
    width: 100%;
  }

  label {
    width: 100%;
  }

  .support-area {
    // width: 120px;
    width: 100%;
    cursor: pointer;
    position: relative;
    display: inline-block;

    // margin-right: 10px;
    &__icon {
      position: relative;
      height: 120px;
      border: 3px solid $border;
      // border-radius: 10px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
      color: $light-grey;

      .fa-question {
        width: 3rem;
        height: 3rem;
      }
    }

    &__image {
      position: relative;
      // height: 120px;
      border: 3px solid $accent1;
      padding-top: 100%;
      // border-radius: 10px;
      background-color: $accent1;
      transition: background-color 0.3s ease-out, border-color 0.3s ease-out;

      img {
        display: block;
        max-width: 70%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.3s ease-out;

        &.inactive-image {
          opacity: 1;
        }

        &.active-image {
          opacity: 0;
        }
      }
    }

    &__title {
      margin: 0.5rem 0 0;
      padding: 0;
      font-family: $font;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.3;
      color: $text;
      transition: color 0.3s ease-out;
    }

    &:hover {

      .support-area__icon,
      .support-area__image {
        border-color: $accent2;
        background-color: $accent1;
      }
    }
  }

  .icon-checked {
    z-index: 1;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }

  input {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked+.support-area {

      .support-area__icon,
      .support-area__image {
        background-color: $accent2;
        border-color: $accent2;
        color: $white;

        .icon-checked {
          opacity: 1;
        }

        img {
          &.inactive-image {
            opacity: 0;
          }

          &.active-image {
            opacity: 1;
          }
        }
      }

      .support-area__title {
        h4 {
          color: $accent2;
        }
      }
    }
  }
}

@media screen and (max-width: 1299px) {
  .checkbox-element .check-cols {
    max-width: 25%;
  }
}

@media screen and (max-width: 992px) {
  .checkbox-element .check-cols {
    max-width: 49%;
  }

  .support-areas {
    margin-top: 30px;

    .support-area {
      margin-top: 15px;

      &__title {
        margin-top: 5px;
      }
    }
  }
}
