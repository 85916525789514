.collapsed>.when-open,
.not-collapsed>.when-closed {
  display: none;
}

.show-toggle-btn {
  display: inline-block;
  text-decoration: none;
  color: $accent2;
  font-family: $font;
  font-weight: 700;
  text-transform: uppercase;
  transition: color 0.3s ease-out;
  cursor: pointer;

  &:hover {
    color: darken($accent2, $vDarken);

    .icon {
      background-color: darken($accent2, $vDarken);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px solid $accent1;
    border-bottom: 1px solid $accent1;
    z-index: 1;
  }

  span {
    background-color: $white;
    position: relative;
    z-index: 2;
    padding: 0 30px;
  }

  .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    top: -4px;
    border-radius: 20px;
    background-color: $accent2;
    color: $white;
    height: 30px;
    width: 60px;
    margin-left: 5px;
    transition: background-color 0.3s ease-out;

    svg {
      height: 27px;
    }
  }
}

.additional-info {
  overflow: hidden;
  transition: height 0.3s ease-out;

  p {
    margin-top: 1rem; // 40px;
  }
}

.checkbox-container {
  margin-top: 2rem;
  color: $text;

  h4 {
    margin: 0;
    padding: 0;
  }

  label {
    font-family: $font;
    font-weight: 700;
    margin-top: 40px;
    position: relative;
    padding-left: 50px;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 36px;
      height: 36px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: $accent1;
      // border: 3px solid $border;
      // border-radius: 10px;
    }

    svg {
      position: absolute;
      left: 18px;
      top: 50%;
      color: $accent2;
      transform: translate(-50%, -50%);
    }

    a {
      color: $accent2;
      text-decoration: underline;
      transition: color 0.3s ease-out;

      &:hover {
        color: $accent2;
      }
    }
  }

  input {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .additional-info p {
    margin-top: 1.3rem;
  }

  .show-toggle-btn {
    white-space: nowrap;

    .icon {
      width: 50px;
      height: 20px;
      top: -2px;
    }
  }

  .checkbox-container {
    padding: 30px 0;

    label {
      margin-top: 1.1rem;
    }

    h4 {
      margin-bottom: 1rem;
    }
  }
}
