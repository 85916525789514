// Normal
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: local("Poppins"),
    url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: italic;
  src: local("Poppins"),
    url("../assets/fonts/Poppins-Italic.ttf") format("truetype");
}

// Medium
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: local("Poppins"),
    url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: italic;
  src: local("Poppins"),
    url("../assets/fonts/Poppins-MediumItalic.ttf") format("truetype");
}

// Semi-Bold
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: local("Poppins"),
    url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: italic;
  src: local("Poppins"),
    url("../assets/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}

// Bold
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: local("Poppins"),
    url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: italic;
  src: local("Poppins"),
    url("../assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
}
