.modal {
  .modal-content {
    border-radius: 0;
    border: none;

    .modal-footer,
    .modal-header,
    .modal-body {
      border: none;
    }

    .modal-header {
      color: $accent2;
    }
  }
}
