@keyframes appear {
  0% {
    opacity: -1;
    z-index: 0;
  }

  25% {
    opacity: 1;
    z-index: 9;
  }

  100% {
    opacity: 1;
    z-index: 9;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    z-index: 9;
  }

  75% {
    opacity: 1;
    z-index: 9;
  }

  100% {
    opacity: 0;
    opacity: -1;
  }
}

.indicator {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;

  &.active {
    animation-name: appear;
    animation-duration: 40ms;
    opacity: 1;
    z-index: 9;
  }

  &.inactive {
    animation-name: disappear;
    animation-duration: 1200ms;
    opacity: -1;
    z-index: 0;
  }

  .indicator-animation {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.home.loading {
  height: 100vh;
  overflow-y: hidden;
}
