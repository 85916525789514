.textarea-element {
  .title {
    margin-top: 3rem;
    font-size: 1.25rem;
  }

  textarea {
    display: block;
    width: 100%;
    min-height: 20vh;
    resize: vertical;
    background-color: $accent1;
    border: 3px solid $accent1;
    // border-radius: 10px;
    font-family: $font;
    font-weight: 400;
    color: $accent2;
    outline: none;
    padding: 0.5rem;
    transition: all 0.3s ease-out;

    &:hover,
    &:focus {
      border: 3px solid $accent2;
    }

    @media screen and (max-width: 992px) {
      min-height: 20vh;
    }
  }
}
