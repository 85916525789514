.link-btn {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font;
  font-weight: 700;
  padding: 1rem 2rem;
  text-transform: uppercase;
  border: 2px solid transparent;
  // border-radius: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
  opacity: 1;
  color: $white;
  border-color: $accent2;
  background-color: $accent2;

  &:hover {
    color: $white;
    border-color: darken($accent2, $vDarken);
    background-color: darken($accent2, $vDarken);
    text-decoration: none;
  }

  svg {
    margin-left: 10px;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: transparent;
    background-color: $inactive;
    color: $white;

    &:hover {
      color: $white;
      background-color: $inactive;
    }
  }

  @media screen and (max-width: 992px) {
    // width: 110px;
    height: 40px;
  }

  @media screen and (max-width: 1440px) {
    height: 50px;
  }
}
