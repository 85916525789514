.notification {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .texts {
    flex: 1;
  }

  .btn {
    color: $white;
  }

  p {
    padding: 0.5rem 1rem;
    color: $white;
    font-family: $font;
    font-weight: 700;
    margin: 0;

    &+p {
      padding-top: 0;
    }
  }
}
