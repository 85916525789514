.date-modal {
  .modal-content {
    border-radius: 0;
    border: none;

    .rdp-root {
      --rdp-accent-color: #009fe3;
      --rdp-today-color: #009fe3;
      --rdp-range_start-color: #009fe3;
      --rdp-range_end-color: #009fe3;
      --rdp-range_middle-foreground-color: #009fe3;
      --rdp-accent-background-color: #d4edfc;
      --rdp-range_middle-background-color: #d4edfc;
      --rdp-range_start-date-background-color: #d4edfc;
      --rdp-range_end-date-background-color: #d4edfc;
    }

    // .rdp {
    //   color: $text;

    //   .rdp-months {
    //     justify-content: center;
    //   }

    //   .rdp-button:hover:not([disabled]) {
    //     background-color: $accent1;
    //   }

    //   .rdp-day_outside {
    //     color: $inactive;
    //   }

    //   .rdp-button:focus:not([disabled]),
    //   .rdp-button:active:not([disabled]) {
    //     border: $accent2;
    //     background-color: $accent1;
    //   }

    //   .rdp-day_selected:not([disabled]),
    //   .rdp-day_selected:focus:not([disabled]),
    //   .rdp-day_selected:active:not([disabled]),
    //   .rdp-day_selected:hover:not([disabled]) {
    //     color: $white;
    //     background-color: $accent2;
    //   }
    // }
  }
}

.date-element {
  margin: $marginSpace 0;

  .btn-date {
    height: 64px;
    width: 64px;

    @media screen and (max-width: 992px) {
      height: 61px;
      width: 61px;
    }

    @media screen and (max-width: 480px) {
      height: 58px;
      width: 58px;
    }
  }
}
