.steps-progress {
  padding: 0;
  position: relative;

  &__progress-bar {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: $accent1;

    span {
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $accent2;
      transition: width 0.3s ease-out;
    }
  }

  &__text {
    padding: 0.5rem 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      // justify-content: space-around;
      li {
        flex: 1;
        display: flex;
        text-align: center;
        font-family: $font;
        font-weight: 700;
        color: $text;
        justify-content: center;

        span.current {
          color: $accent2;
        }
      }
    }
  }
}

// @media screen and (max-width: 1440px) {
//   .steps-progress {
//     &__text {
//       padding: 20px 0 10px;
//     }
//   }
// }

@media screen and (max-width: 992px) {
  .steps-progress {
    &__progress-bar {
      height: 5px;
    }
  }
}
