.text-element {

  h1,
  h2,
  h3 {
    margin-top: 3rem;
  }

  p+p {
    margin-top: 0.5rem;
  }
}

.first .text-element {

  h1,
  h2,
  h3 {
    margin-top: 0;
  }
}
