.input-element {
  margin: $marginSpace 0;

  input {
    display: block;
    width: 100%;
    border: 3px solid $accent1;
    text-align: center;
    // padding: 30px;
    padding: 20px 10px;
    font-family: $font;
    font-weight: 700;
    font-size: 1rem;
    color: $accent2;
    background-color: $accent1;
    // border-radius: 10px;
    opacity: 1;
    position: relative;
    outline: none;
    transition: all 0.3s ease-out;

    &::placeholder {
      color: $accent2;
      font-family: $font;
      font-weight: 400;
      font-style: italic;
    }

    &:focus,
    &:hover {
      border-color: $accent2;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }
}

@media screen and (max-width: 992px) {
  .post-index {
    h2 {
      margin-bottom: 15px;
    }

    input {
      height: 60px;
      padding: 0 5px;
    }
  }
}

.personal-advice-from__inner {
  .input-element {
    margin: 1rem 0;

    input {
      margin-bottom: 1rem;
    }
  }

  .steps-navigation {
    margin: 3rem 0 0;
  }
}
