.header {
  padding: 1rem 0;

  .main-title {
    font-family: $font;
    font-weight: 700;
    font-size: 1.75rem;
    text-align: right;
    color: $text;

    span {
      display: block;
      color: $accent2;
    }
  }

  .logo {
    display: block;
    // margin-left: auto;
    width: 100%;
    max-width: 250px;
    height: auto;
  }

  .header-btn {
    border: none;
    padding: 0;
    background-color: transparent;
    outline-offset: 0.5rem;

    h2 {
      margin: 0;
    }
  }

  .main-title-container {
    text-align: right;
  }

  .btn {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    box-shadow: none;
    padding: 5px 18px;
    margin: 0;
    font-family: $font;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s ease-out;
    outline-offset: 0.5rem;

    svg {
      margin-right: 14px;
    }

    &--login {
      background-color: $accent1;
      color: $white;

      // border-radius: 10px;
      &:hover {
        background-color: darken($accent1, $vDarken);
      }
    }

    &--register {
      color: $inactive;

      &:hover {
        color: $accent2;
      }
    }
  }

  // @media screen and (max-width: 1440px) {
  //   padding: 30px 0;
  // }
  @media screen and (max-width: 1240px) {

    // padding: 20px 0;
    .main-title span {
      display: block;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 0.5rem 0;

    .logo {
      margin-left: 0;
      max-width: 150px;
    }

    .main-title {
      text-align: center;
      margin: 15px 0;
    }

    .main-title-container {
      text-align: center;
    }

    .btn {
      padding: 0 10px;
    }
  }

  @media screen and (max-width: 480px) {
    .main-title {
      font-weight: bold;
      text-align: right;
      font-size: 1rem;
      margin: 0;
    }
  }
}
