.title {
  color: $text;
  font-family: $font;
  font-weight: 700;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  @media screen and (max-width: 480px) {
    font-size: 1.25rem;
  }
}

.input-title {
  color: $text;
  font-family: $font;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.subtitle {
  color: $text;
  font-family: $font;
  font-weight: 400;
}

.icon-checked {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid $white;
  color: $white;
  background-color: $accent2;
  position: absolute;
  // width: 60px;
  width: 30px;
  // height: 60px;
  height: 30px;
  // box-shadow: 0px 1px 2px black;
  border-radius: 50%;
  transition: opacity 0.3s ease-out;
}

.element+.element {
  margin-top: 50px;
}

.alert {
  margin-top: 1rem;
  padding: 10px;
}

@import "./check";
@import "./radio";
@import "./input";
@import "./text";
@import "./select";
@import "./textarea";
@import "./date";
@import "./link";
