.teaser {
  display: block;
  text-decoration: none;
  color: $text;
  font-family: $font;
  font-weight: 700;
  transition: all 0.3s ease-out;
  overflow: hidden;
  border: 3px solid $border;
  // border-radius: 10px;
  margin: 15px 0;

  a {
    color: $text;
    text-decoration: none;
  }

  &__photo {
    overflow: hidden;
    max-height: 360px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  &__title {
    padding: 1rem 1.5rem;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    opacity: 0;
  }

  &:hover a {
    color: $accent2;
  }
}

@media screen and (max-width: 992px) {
  .teaser {
    &__title {
      padding: 15px;
    }
  }
}
