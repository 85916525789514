.check-icon {
  margin-right: 0.5rem;
  position: relative;
  top: 0.6rem;
}

.sidebar {
  padding: 3rem 15px;
  height: 100%;
  background-color: $accent1;
  position: relative;
  color: $text;

  @media screen and (max-width: 480px) {
    min-height: 0;
  }

  h4.blue,
  h5.blue {
    color: $accent2;
    text-transform: uppercase;
    margin: 0;
  }

  .step-info {
    position: relative;
    margin-top: 1rem;

    &__item {
      cursor: pointer;
      background-color: $white;
      padding: 10px 15px;
      color: $grey;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      transition: background-color 0.3s ease-out;

      &+.step-info__item {
        margin-top: 15px;
      }

      &:hover {
        color: darken($grey, $vDarken);
        background-color: darken($white, $vDarken);

        &:before,
        &:after {
          background-color: darken($white, $vDarken);
        }
      }

      &:before {
        right: 100%;
      }

      &:after {
        left: 100%;
      }

      .title-line {
        display: flex;
        align-items: flex-start;

        h6 {
          flex: 1;
        }

        .edit-icon {
          padding-left: 0.5rem;
          flex-shrink: 0;
          color: $accent2;
        }
      }

      .text-line {
        display: flex;
        align-items: flex-start;
        min-width: 0;

        svg {
          flex-shrink: 0;
          height: 1.5rem;
        }

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-left: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .sidebar {
    padding: 15px;

    &:before {
      display: none;
    }

    .check-icon {
      top: 0;
    }

    .step-info {

      &:before,
      &:after {
        width: 15px;
      }

      &__item {

        &:before,
        &:after {
          width: 15px;
        }
      }
    }
  }
}
