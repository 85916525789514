* {
  font-family: $font;
  font-weight: 400;
}

body,
html {
  height: 100%;
  width: 100%;
  font-size: 16px;

  @media screen and (max-width: 992px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-family: $font;
  font-weight: 700;
  color: $text;
}

.footer a {
  color: $accent2;

  &:hover {
    color: darken($accent2, $vDarken);
  }
}

.btn.btn-blue {
  border: 2px solid $accent2;
  background-color: $accent2;
  color: $white;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s ease-out;

  &:hover {
    border-color: darken($accent2, $vDarken);
    background-color: darken($accent2, $vDarken);
  }

  &:disabled {
    border-color: transparent;
    background-color: $inactive;

    &:hover {
      cursor: not-allowed;
      border-color: transparent;
      background-color: darken($inactive, $vDarken);
    }
  }
}

.btn.btn-white {
  border: 2px solid $accent1;
  background-color: $white;
  color: $accent2;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s ease-out;

  &:hover {
    border-color: darken($accent1, $vDarken);
    color: darken($accent2, $vDarken);
  }
}

.bg-acc1 {
  background-color: $accent1;
}

.bg-acc2 {
  background-color: $accent2;
}

.text-acc2 {
  color: $accent2;
}

.alert {
  border-radius: 0;
  border: none;
}

@media screen and (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }
}

p {
  color: $text;
  font-family: $font;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.content {
  p+p {
    margin-top: 1rem;
  }
}

.app {
  background-color: $grey;
}

.home {
  background-color: $white;
  overflow: hidden;
  height: 100vh;
  // max-height: 100vh;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    display: block;
    overflow: visible;
  }
}

header {
  width: 100%;
  z-index: 10;
  background-color: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  // flex: 0;
  position: fixed;
}

main {
  position: relative;
  background-color: $white;
  // max-height: 100vh;
  flex: 1;
  overflow-y: auto;
  // display: flex;
  // & > .container {
  //   flex: 1;
  //   display: flex;
  //   & > .row {
  //     flex: 1;
  //   }
  // }
}

footer {
  z-index: 1;
  width: 100%;
  // flex: 0;
}

.text-page {
  p {
    margin-bottom: 5;
  }
}

@media screen and (max-width: 992px) {
  .main-content {
    padding: 15px;
  }

  .top-bar,
  .bottom-bar {
    position: relative;
  }
}
