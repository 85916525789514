.steps-navigation {
  margin: 5rem 0 2rem;
  // background-color: $white;
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.step-5-subnav {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    font-size: 0;
    line-height: 0;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;

    a {
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: rgba($grey, 0.25);
      transition: background-color 0.3s ease-out;

      &:hover,
      &.current {
        background-color: $accent1;
      }
    }
  }
}

.step-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font;
  font-weight: 700;
  padding: 1rem 2rem;
  text-transform: uppercase;
  border: 2px solid transparent;
  // border-radius: 10px;
  transition: all 0.3s ease-out;

  &--next {
    cursor: pointer;
    opacity: 1;
    color: $white;
    border-color: $accent2;
    background-color: $accent2;

    &:hover {
      border-color: darken($accent2, $vDarken);
      background-color: darken($accent2, $vDarken);
    }

    svg {
      margin-left: 10px;
    }
  }

  &--prev {
    cursor: pointer;
    color: $accent2;
    border: 2px solid $accent1;
    background-color: $white;

    svg {
      margin-right: 10px;
    }

    &:hover {
      border-color: darken($accent1, $vDarken);
      color: darken($accent2, $vDarken);
    }
  }

  &:disabled {
    cursor: not-allowed;
    border-color: transparent;
    background-color: $inactive;
    color: $white;

    &:hover {
      color: $white;
      background-color: $inactive;
    }
  }
}

@media screen and (max-width: 992px) {
  .step-btn {
    width: 110px;
    height: 40px;
  }
}

@media screen and (max-width: 1440px) {
  .step-btn {
    height: 50px;
  }
}
