// Colors
$black: #000000;
$white: #ffffff;
$text: #575757;
$border: #e3e3e3;
$accent1: #d4edfc;
$accent2: #009fe3;
$accent3: #ea6768;
$grey: #575757;
$light-grey: #e3e3e3;
$inactive: rgba($grey, 0.5);
$purple: #dddaee;
$green: #e2edd0;
$olive: #e3ece4;
$yellow: #fff9c7;
$orange: #fde6bb;
$red: #fbdad3;

// Values
$vDarken: 5%;

// Fonts
$font: "Poppins", "Arial", sans-serif;

// spacing
$marginSpace: 1rem;
